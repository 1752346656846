<template>
  <div>
    <QuestionnaireIndicatorSelectDisplay
      :selected="selectedIndicatorInCategory"
      :on-edit="toggleModal"
      :indicator-type="indicatorType"
      :subcategory="subcategory"
      :edit-display="editDisplay"/>
    <a-modal
      width="620px"
      class="indicator-selector-modal"
      :dialog-style="{ display: 'none' }"
      :footer="null"
      :closable="!savingIndicators"
      :visible="open"
      @cancel="closeModal">
      <h1 class="txt-32 txt-font-din-medium">{{ translateIndicatorType(indicatorType) }}</h1>
      <div class="txt-22 mt-16 mb-22 txt-font-din-medium indicator-subject">
        {{ $t('components.titles.surveySubject') }} <span class="txt-capitalize">{{ translateIndicator(subcategory) }}</span>
      </div>
      <a-input v-model="search"
        class="indicator-search"
        :placeholder="$t('components.titles.search') "
        allow-clear
        size="large">
        <a-icon slot="prefix" type="search" style="color: #c6c7c6;"/>
      </a-input>
      <a-tabs :bordered="true">
        <a-tab-pane v-if="Object.keys(groupedIndicators).length === 0 && search.length !== 0" tab="Search">
          <a-alert
            :message="$t('components.titles.noIndicatorsFound')"
            type="warning"
            show-icon/>
        </a-tab-pane>
        <a-tab-pane
          v-if="Object.keys(groupedIndicators).length === 0 && search.length === 0"
          tab="No Indicators">
          <a-alert
          :message="$t('components.titles.noIndicatorsFound')"
            type="warning"
            show-icon/>
        </a-tab-pane>
        <a-tab-pane v-for="(subcat, key) of groupedIndicators" :key="key" :tab="translateIndicator(key)"
          class="question-container">
          <div v-for="(sub, subKey) of subcat" :key="subKey">
            <div v-if="subKey === 'null'">
              <a-checkbox-group v-model="selected[key]">
                <a-checkbox
                  v-for="item in sub"
                  :id="String(item.id)"
                  :key="item.id"
                  :value="item.id"
                  :disabled="isGeneralInfo(item) || isSupervisor">
                <label class="indicator-label" :for="String(item.id)">{{ displayIndicator(item) }} {{ isGeneralInfo(item) ? `(${$t('values.required')})` : '' }}</label>

                <div v-if="isSelectedItemAnaemia(item, selected[key]) && !isGpsMetadataEnabled" class="notice-container">
                  <span class="txt-bold">{{ $t('components.titles.gps') }}</span> {{ $t('components.titles.optionsTurnedOffMetadata') }}
                </div>
                </a-checkbox>
              </a-checkbox-group>
            </div>
            <div v-else>
              <a-checkbox
                :checked="selected[`${key}_${subKey}`] && selected[`${key}_${subKey}`].length === sub.length"
                :disabled="hasSubindicatorsWithExclusions(sub) || isSupervisor"
                @change="onCheckAllChange(sub, `${key}_${subKey}`)">
                {{ translateGrouping(subKey) }}
              </a-checkbox>
              <div class="mt-8 sub-indicators">
                <a-checkbox-group v-model="selected[`${key}_${subKey}`]">
                  <div v-for="item in sub" :key="item.id">
                    <a-checkbox
                      :id="String(item.id)"
                      :value="item.id"
                      :disabled="isExcludedIndicatorSelected(sub, `${key}_${subKey}`, item.excludedIfSelected) || isSupervisor"
                      style="align-items: top;"
                      class="ml-40 flex indicator-checkbox">
                      <label class="indicator-label" :for="String(item.id)">{{ displayIndicator(item) }}</label>
                    </a-checkbox>
                    <div
                      v-if="isExcludedIndicatorSelected(sub, `${key}_${subKey}`, item.excludedIfSelected)"
                      class="notice-container">
                      {{ $t('components.titles.tobeAbleToChoose') }} <span class="txt-bold">{{ displayIndicator(item) }}</span> {{ $t('components.titles.youHaveToUncheck') }} <span class="txt-bold">{{ getExcludedIndicator(item.excludedIfSelected, sub) }}</span>
                    </div>
                  </div>
                </a-checkbox-group>
              </div>
            </div>
          </div>
        </a-tab-pane>
      </a-tabs>
      <div class="flex justify-space-between mt-22">
        <a-button size="large" class="w170 cancel-button" @click="toggleModal">{{ $t('values.cancel') }}</a-button>
        <a-button type="primary" size="large" class="w170 save-button" :disabled="isSupervisor" :loading="savingIndicators" @click="save">{{ $t('values.save') }}</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import groupBy from 'lodash/groupBy';
import uniqBy from 'lodash/uniqBy';
import isEqual from 'lodash/isEqual';
import uniq from 'lodash/uniq';
import values from 'lodash/values';
import flattenDeep from 'lodash/flattenDeep';
import differenceBy from 'lodash/differenceBy';
import { mapActions, mapState } from 'vuex';
import { translateIndicator, translateGrouping } from '@/util/indicators';
import QuestionnaireIndicatorSelectDisplay from './indicator-select-display.vue';

export default {
  name: 'IndicatorSelector',
  components: {
    QuestionnaireIndicatorSelectDisplay
  },
  props: {
    category: {
      type: String,
      default: undefined
    },
    subcategory: {
      type: String,
      default: undefined
    },
    possibleIndicators: {
      type: Array,
      default: () => ([])
    },
    selectedIndicators: {
      type: Array,
      default: () => ([])
    },
    editDisplay: { // this should be removed in the new ui change
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      open: false,
      selected: {},
      search: ''
    };
  },
  computed: {
    ...mapState({
      surveyId: state => state.survey.surveyId,
      metadata: state => state.survey.metadata || {},
      savingIndicators: state => state.indicators.saving,
      isSupervisor: state => state.survey.currentUserRoleSystem === 'srvy-sup'
    }),
    selectedIndicatorInCategory() {
      return this.selectedIndicators
        .filter(indicator =>
          indicator.category === this.category &&
          indicator.subcategory === this.subcategory);
    },
    isGpsMetadataEnabled() {
      const { allowGPS } = this.metadata;
      return allowGPS === true;
    },
    indicatorType() {
      return this.category === 'core' ? 'core' : 'bank';
    },
    groupedIndicators() {
      if (!Array.isArray(this.possibleIndicators)) {
        return [];
      }
      const indicators = this.search.length === 0 ? this.possibleIndicators
        : this.possibleIndicators
          .filter(indicator => indicator.name.toUpperCase()
            .includes(this.search.toUpperCase()));

      const items = indicators
        .filter(indicator => indicator.category === this.category)
        .filter(indicator => indicator.subcategory === this.subcategory);

      const subCategories = groupBy(items, 'subcategory');
      const keys = Object.keys(subCategories);
      const grouped = {};
      keys.forEach(key => {
        grouped[key] = groupBy(subCategories[key], 'grouping');
      });
      return grouped;
    },
    componentSelectedIndicators() {
      const selected = flattenDeep(values(this.selected));
      const indicators = selected
        .filter(id => this.possibleIndicators.findIndex(indicator => indicator.id === id) > -1);

      return indicators;
    }
  },
  watch: {
    selectedIndicators(newVal) {
      this.setIndicatorFromStore(newVal);
    }
  },
  created() {
    if (this.selectedIndicators) {
      this.setIndicatorFromStore(this.selectedIndicators);
    }
  },
  methods: {
    ...mapActions([
      'saveSelectedIndicators'
    ]),
    translateAbbrevation(abb) {
      const abbrevation = {
        rCSI: this.$t('components.description.rCSI'),
        HDDS: this.$t('components.description.HDDS'),
        FCS: this.$t('components.description.FCS'),
        HHS: this.$t('components.description.HHS'),
        MUAC: this.$t('components.description.MUAC')
      };
      return abbrevation[abb];
    },
    translateIndicatorType(indicatorType) {
      let translatedIndicator = '';
      if (indicatorType === 'core') {
        translatedIndicator = this.$t('components.titles.addOrRemoveCoreIndicators');
      } else {
        translatedIndicator = this.$t('components.titles.addOrRemoveBankIndicators');
      }
      return translatedIndicator;
    },
    translateIndicator(indicator) {
      return translateIndicator.call(this, indicator);
    },
    translateGrouping(group) {
      return translateGrouping.call(this, group);
    },
    hasSubindicatorsWithExclusions(sub) {
      return sub.some(item => item.excludedIfSelected !== null);
    },
    isGeneralInfo(item) {
      return item.name === 'general_info_demo';
    },
    isSelectedItemAnaemia(item, selectedIndicators) {
      return (item.name === 'child_anaemia' || item.name === 'women_anaemia') && selectedIndicators && selectedIndicators.includes(item.id);
    },
    displayIndicator(item) {
      const displayName = this.translateIndicator(item.name);
      return `${displayName}${item.abbreviation ? ` (${this.translateAbbrevation(item.abbreviation)})` : ''}`;
    },
    displayIndicatorAbbreviation(item) {
      const displayName = this.translateIndicator(item.name);
      return item.abbreviation ? this.translateAbbrevation(item.abbreviation) : displayName;
    },
    getExcludedIndicator(excludedItem, subIndicators) {
      const indicatorsToCheck = subIndicators
        .filter(subInd => excludedItem
          .findIndex(name => name === subInd.name) > -1);

      return indicatorsToCheck.map(item => this.displayIndicator(item)).join(', ');
    },
    capitalizeFirstLetter(name) {
      return name.charAt(0).toUpperCase() + name.slice(1);
    },
    setIndicatorFromStore(indicators) {
      const categoryIndicators = indicators
        .filter(indicator =>
          indicator.category === this.category &&
          indicator.subcategory === this.subcategory);

      this.selected = categoryIndicators.reduce((accum, curr) => {
        const key = curr.grouping ? `${curr.subcategory}_${curr.grouping}` : curr.subcategory;

        const keyObj = accum[key] || [];
        return {
          ...accum,
          [key]: [...keyObj, curr.id]
        };
      }, {});
    },
    toggleModal() {
      this.open = !this.open;
    },
    closeModal() {
      if (this.savingIndicators) {
        return;
      }
      this.open = false;
    },
    async save() {
      const newIndicators = this.componentSelectedIndicators;
      const previousIndicators = this.selectedIndicators.map(item => item.id);

      const previouslySelectedComponentIndicators = this.selectedIndicatorInCategory.map(item => item.id);

      let indicatorsToSave = [];
      if (this.selectedIndicators && this.selectedIndicators.length > 0) {
        const other = differenceBy(previousIndicators, previouslySelectedComponentIndicators);
        indicatorsToSave = indicatorsToSave.concat(other, newIndicators);
      } else {
        indicatorsToSave = newIndicators;
      }

      await this.saveSelectedIndicators({ indicators: indicatorsToSave, surveyId: this.surveyId });
      this.closeModal();
    },
    isExcludedIndicatorSelected(indicators, key, excludedIfSelected) {
      if (excludedIfSelected == null) {
        return false;
      }
      const indicatorsToCheck = indicators
        .filter(subInd => excludedIfSelected
          .findIndex(name => name === subInd.name) > -1);

      if (this.selected[key] && this.selected[key].length > 0) {
        const selectedIndicatorIds = this.selected[key];

        for (const indicator of indicatorsToCheck) {
          if (selectedIndicatorIds.findIndex(selected => selected === indicator.id) > -1) {
            return true;
          }
        }
      }
      return false;
    },
    onCheckAllChange(indicators = [], key) {
      if (this.selected[key] && this.selected[key].length === indicators.length) {
        this.selected[key] = [];
        return;
      }

      this.$set(this.selected, key, indicators.map(indicator => indicator.id));
    }
  }
};
</script>

<style lang="scss">
.indicator-selector-modal {

  .ant-tabs-bar {
    background: #f4f4f4;
    border: 1px solid #e8e8e8;
    border-bottom: none;
  }

  .ant-modal-close-icon {
    font-size: 22px;
    color: #c6c7c6;
    margin-top: 10px;
  }

  .ant-tabs-bar {
    margin-bottom: 0;
  }

  .ant-tabs {
    border-left: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
  }

  .ant-tabs-tab {
    text-transform: capitalize;
    font-family: 'DINNextLTPro-Medium';
  }

  .ant-checkbox-wrapper {
    font-size: 16px;
    font-weight: normal;
    margin-top: 2px;
    margin-bottom: 2px;
    display: block;
  }

  .cancel-button {
    color: #e97200;
    border: 2px solid #e97200;
  }

  .ant-tabs-tab-active {
    color: #e97200 !important;
  }

  .ant-tabs-content {
    overflow-y: scroll;
  }

  .ant-tabs-tabpane {
    min-height: 200px;
  }

  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin: 5px 0;
  }

  .ant-checkbox-group .ant-checkbox {
    height: 16px;
  }

  .question-container {
    margin: 15px 0px 0px 20px;
    font-size: 16px;
  }

  .notice-container {
    margin: 5px 0 5px 25px;
    padding: 10px 0px 8px 8px;
    color: #3897ffcc;
    background: #e6f7ffb5;
    border: solid 1px #3897ffcc;
    max-width: 87%;
  }

  .sub-indicators .notice-container {
    margin: 5px 0 15px 60px;
  }

  .indicator-label {
    cursor: pointer;
    font-size: 16px;
    font-weight: normal;
    margin-top: 2px;
    margin-bottom: 2px;
    font-family: 'DINNextLTPro-Regular';
  }
}
.indicator-checkbox .ant-checkbox {
  margin-top: 6px;
}
</style>
