function langs() {
  return [
    {
      label: this.$t('components.dropdown.english'),
      value: 'English',
      disabled: true
    },
    {
      label: this.$t('components.dropdown.portuguese'),
      value: 'Portuguese'
    },
    {
      label: this.$t('components.dropdown.arabic'),
      value: 'Arabic'
    },
    {
      label: this.$t('components.dropdown.french'),
      value: 'French'
    },
    {
      label: this.$t('components.dropdown.spanish'),
      value: 'Spanish'
    }
  ];
}

export default langs;
