<template>
  <div style="margin-bottom: 20px;">
    <a-card :bordered="true" class="w-100 mb-40 form-create">
      <div class="align-center pb-24 form-create-title">
        <embed width="40px" class="mr-16" src="/create-form-icon.svg">
        <div class="txt-22 txt-bold txt-font-din-bold w-100 txt-black">
          {{ $t('components.titles.createNewForm') }}
        </div>
        <a v-if="activeLanguages.length === 0" class="txt-18 form-create-cancel justify-self-end" :class="{ 'txt-orange': !isSupervisor }" :disabled="isSupervisor" @click="clear(true)">
          {{ $t('values.cancel') }}
        </a>
      </div>
      <div v-if="activeLanguages.length === 0">
        <div class="txt-bold txt-font-din-bold txt-black txt-20 mb-16">{{ $t('components.titles.coreIndicators') }}</div>
        <a-tabs type="card" class="core-indicators">
          <a-tab-pane key="1">
            <span slot="tab" data-cy="questionnaire-indicator-core-household-tab">
              {{ $tc('components.titles.household', 1) }}
            </span>
            <div class="flex align-center pl-24 pt-32 pb-32">
              <IndicatorSelector
                :possible-indicators="selectableIndicators"
                :selected-indicators="selectedIndicators"
                category="core"
                subcategory="household"/>
            </div>
          </a-tab-pane>
          <a-tab-pane key="2">
            <span slot="tab" data-cy="questionnaire-indicator-core-child-tab">
              {{ $t('components.titles.child') }}
            </span>
            <div class="flex align-center pl-24 pt-32 pb-32">
              <IndicatorSelector
                :possible-indicators="selectableIndicators"
                :selected-indicators="selectedIndicators"
                category="core"
                subcategory="child"/>
            </div>
          </a-tab-pane>
          <a-button
            v-if="hasCoreIndicatorsSelected"
            slot="tabBarExtraContent"
            type="link"
            :disabled="isSupervisor"
            @click="resetIndicators(true)">
              <a-icon
                type="delete"
                :disabled="isSupervisor"
                :class="{ 'txt-red': !isSupervisor }"
                style="font-size: 20px;"/>
          </a-button>
        </a-tabs>
        <div class="txt-bold txt-font-din-bold txt-black txt-20 mt-32 mb-16">{{ $t('components.titles.addFromIndicatorBank') }}</div>
        <a-tabs type="card" class="bank-indicators">
          <a-tab-pane key="1">
            <span slot="tab" data-cy="questionnaire-indicator-bank-household-tab">
              {{ $tc('components.titles.household', 1) }}
            </span>
            <div class="flex align-center pl-24 pt-32 pb-32">
              <IndicatorSelector
                :possible-indicators="selectableIndicators"
                :selected-indicators="selectedIndicators"
                category="indicator bank"
                subcategory="household"/>
            </div>
          </a-tab-pane>
          <a-tab-pane key="2">
            <span slot="tab" data-cy="questionnaire-indicator-bank-child-tab">
              {{ $t('components.titles.child') }}
            </span>
            <div class="flex align-center pl-24 pt-32 pb-32">
              <IndicatorSelector
                :possible-indicators="selectableIndicators"
                :selected-indicators="selectedIndicators"
                category="indicator bank"
                subcategory="child"/>
            </div>
          </a-tab-pane>
          <a-tab-pane key="3">
            <span slot="tab" data-cy="questionnaire-indicator-bank-women-tab">
              {{ $t('components.titles.women') }}
            </span>
            <div class="flex align-center pl-24 pt-32 pb-32">
              <IndicatorSelector
                :possible-indicators="selectableIndicators"
                :selected-indicators="selectedIndicators"
                category="indicator bank"
                subcategory="women"/>
            </div>
          </a-tab-pane>
          <a-button
            v-if="hasBankIndicatorsSelected"
            slot="tabBarExtraContent"
            type="link"
            @click="resetIndicators(false)">
              <a-icon
                type="delete"
                class="txt-red"
                style="font-size: 20px;"/>
          </a-button>
        </a-tabs>
      </div>
      <div v-if="activeLanguages.length != 0" class="indicator-items">
        <div class="txt-bold txt-font-din-medium mt-16 ml-16 mb-16 pb-50 txt-16">{{ $t('components.titles.coreIndicators').toUpperCase() }}</div>
        <div class="flex ml-20 mr-20 pt-20 pb-70 divider-top">
          <div v-if="displayHouseholdCore()" style="flex: 1">
            <h4>{{ $tc('components.titles.household', 1) }}</h4>
            <IndicatorSelector
              :edit-display="false"
              :possible-indicators="selectableIndicators"
              :selected-indicators="selectedIndicators"
              category="core"
              subcategory="household"/>
          </div>
          <div v-if="displayChildCore()" style="flex: 2">
            <h4>{{ $t('components.titles.child') }}</h4>
            <IndicatorSelector
              :edit-display="false"
              :possible-indicators="selectableIndicators"
              :selected-indicators="selectedIndicators"
              category="core"
              subcategory="child"/>
          </div>
        </div>
        <div v-if="displayBankIndicator()" class="txt-bold txt-font-din-medium txt-16 mt-16 ml-16 mb-16">{{ $t('components.titles.addFromIndicatorBank').toUpperCase() }}</div>
        <div v-if="displayBankIndicator()" class="flex ml-20 mr-20 pt-20 pb-20 divider-top">
          <div v-if="displayHouseholdBankIndicator()" style="flex: 1">
            <h4>{{ $tc('components.titles.household', 1) }}</h4>
            <IndicatorSelector
              :edit-display="false"
              :possible-indicators="selectableIndicators"
              :selected-indicators="selectedIndicators"
              category="indicator bank"
              subcategory="household"/>
          </div>
          <div v-if="displayChildBankIndicator()" style="flex: 1">
            <h4>{{ $t('components.titles.child') }}</h4>
            <IndicatorSelector
              :edit-display="false"
              :possible-indicators="selectableIndicators"
              :selected-indicators="selectedIndicators"
              category="indicator bank"
              subcategory="child"/>
          </div>
          <div v-if="displayWomenBankIndicator()" style="flex: 1">
            <h4>{{ $t('components.titles.women') }}</h4>
            <IndicatorSelector
              :edit-display="false"
              :possible-indicators="selectableIndicators"
              :selected-indicators="selectedIndicators"
              category="indicator bank"
              subcategory="women"/>
          </div>
        </div>
      </div>
      <div class="flex justify-space-between align-center">
        <a-button
          v-if="activeLanguages.length === 0"
          class="mt-40 mb-40 w200 create-in-form-builder-button"
          type="primary"
          size="large"
          @click="openLanguageModal">
          {{ $t('components.titles.createInFormBuilder') }}
        </a-button>
        <div class="mt-40 mb-40">
          <a-button
            v-if="activeLanguages.length != 0"
            class="w200 create-in-form-builder-button"
            type="primary"
            size="large"
            @click="resumeFormBuilder">
            {{ $t('components.titles.resumeEditing') }}
          </a-button>
        </div>
        <a-popconfirm
          :title="$t('components.notifications.areYouSureYouWantToDeleteThisForm')"
          :ok-text="$t('values.yes')"
          :cancel-text="$t('values.no')"
          class="mt-40 mb-40"
          :disabled="isSupervisor"
          @confirm="destroy">
          <a-button
            v-if="activeLanguages.length != 0"
            type="danger"
            ghost
            icon="trash"
            :disabled="isSupervisor"
            size="large">
            {{ $t('components.titles.destroyFormInFormBuilder') }}
          </a-button>
        </a-popconfirm>
      </div>
      <LanguageModal
        :visible="isLanguageModalVisible"
        :existing-languages="defaultLanguages"
        :close-language-modal="closeLanguageModal"
        :on-complete="openFormBuilder"
        :value="languageIndex"
        @languageChange="emitLanguageChange"/>
      <FormBuilder
        v-if="odkBuildForm !== null && builderActive"
        :built-form="odkBuildForm"
        :active="builderActive"
        :close="closeFormBuilder"
        :on-complete="onComplete"/>
    </a-card>
    <div class="txt-bold txt-font-din-bold txt-20 text-uppercase txt-black additional-translation-title">{{ $t('components.titles.additionalTranslations') }}</div>
    <p class="txt-16" v-html="$t('components.description.afterBuildingFormTranslationTemplate')"></p>
    <a-alert
      v-if="activeLanguages.length === 0"
      class="txt-16"
      type="info"
      :message="$t('components.notifications.useTranslationTemplate')"
      show-icon/>
    <div v-if="activeLanguages.length != 0">
      <div class="flex mb-10">
        <a-button
          type="link"
          class="txt-18 txt-black txt-font-din-medium mr-10 flex align-center"
          data-cy="create-form-download-translation-template-button"
          @click="downloadQuestionnaireTemplate">
          <embed width="30px" src="/icon-cloud-download.svg" class="mr-10">
          {{ $t('components.labels.downloadTranslationTemplate') }}
        </a-button>
        <a-button
          type="link"
          class="txt-18 txt-black txt-font-din-medium mr-10 flex align-center"
          data-cy="create-form-upload-translation-template-button"
          :disabled="isSupervisor"
          @click="openUploadModal">
          <embed width="30px" :src="isSupervisor ? '/Upload.svg' : '/Upload-orange.svg'" class="mr-10">
          {{ $t('components.labels.uploadTranslationTemplate') }}
        </a-button>
      </div>
      <a-card class="additional-card">
        <div class="divider-bottom-thick">
          <div class="pb-20 pt-20 pl-20 pr-20 flex justify-space-between align-center">
          <div class="txt-bold" style="flex: 1; color: black;">{{ $t('components.titles.language').toUpperCase() }}</div>
          <div class="txt-bold" style="flex: 2; color: black;">{{ $t('components.titles.status').toUpperCase() }}</div>
          <div class="txt-bold" style="flex: 1; color: black;">{{ $t('components.titles.actions').toUpperCase() }}</div>
        </div>
        </div>
        <div v-for="item in activeLanguages" :key="item.key" class="divider-bottom-thick">
          <div class="pb-20 pt-20 pl-20 pr-20 flex justify-space-between align-center">
            <div class="txt-bold" style="flex: 1">
            {{ languages(item.language) }}
            <a-tag v-if="item.isDefault">{{ $t('components.labels.default') }}</a-tag>
            </div>
            <div class="txt-bold" style="flex: 2">
              <a-tag
                :color="item.missingFields.length === 0 ? 'green' : 'orange'" class="ml-4">
              <a-icon :type="item.missingFields.length === 0 ? 'check-circle' : ''" class="mr-4"/>
              {{ item.missingFields.length === 0 ? $t('components.labels.translationsProvided') : $t('components.labels.translationFieldsMissing') }}
              </a-tag>
            </div>
            <div class="txt-bold" style="flex: 1">
              <div class="txt-bold flex">
                <div style="flex: 1">
                  <a v-if="!item.isDefault" :disabled="item.missingFields.length !== 0 || isSupervisor" :class="['txt-bold', item.missingFields.length === 0 && !isSupervisor ? 'txt-black' : 'txt-grey']" @click="setDefaultLanguage(item.key)">{{ $t('components.description.setAsDefault') }}</a>
                </div>
                <a-popover
                  :visible="removeLanguageConfirmIndex === item.key"
                  placement="left"
                  trigger="click"
                  :disabled="isSupervisor"
                  @visibleChange="(_) => removeLanguageConfirmIndex = item.key">
                  <template slot="content">
                    <div class="flex m-10">
                      <a-icon
                        slot="dot"
                        theme="filled"
                        type="info-circle"
                        class="txt-red"
                        style="font-size: 16px;"/>
                      <div class="delete-language-popup">
                        <p v-html="$t('components.description.deletingLanguageWillRemovePermanently')"></p>
                        <div class="flex justify-space-between">
                          <a-button type="primary" @click="() => removeLanguageConfirmIndex = undefined">{{ $t('values.cancel') }}</a-button>
                          <a-button class="ml-10" type="primary" ghost style="flex: 1" @click="deleteLanguage(item.key)">{{ $t('components.description.deleteLanguage') }}</a-button>
                        </div>
                      </div>
                    </div>
                  </template>
                  <a v-if="item.key !== 0" :disabled="item.isDefault || isSupervisor" @click="() => removeLanguageConfirmIndex = item.key"><img alt="delete language icon" :src="item.isDefault || isSupervisor ? '/trash-bin-disabled.svg' : '/trash-bin.svg'"></a>
                </a-popover>
              </div>
            </div>
          </div>
        </div>
        <div class="new-line-row pb-20 pl-20" data-cy="create-form-add-a-language-button" :class="{ 'new-line-row-disabled': isSupervisor }" @click="() => openAddLanguageModal()">
          <p class="add-icon">+</p>
          <span class="add-new-line-text">{{ $t('components.labels.addALanguage') }}</span>
        </div>
      </a-card>
    </div>
    <addLanguageModal
      :visible="isAddLanguageModalVisible"
      :close="closeAddLanguageModal"/>
    <UploadModal
      :upload-visible="uploadVisible"
      @on-close="uploadVisible = false"/>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import { apiPaths, isProblem } from '../../../../util/request';
import { noop } from '../../../../util/util';
import { requestData } from '../../../../store/modules/request';
import IndicatorSelector from '../indicators/indicator-selector.vue';
import request from '../../../../mixins/request';
import enumerators from '../../../../mixins/enumerators';
import langs from '../../../../util/languages';


export default {
  name: 'QuestionnaireFormCreate',
  components: {
    IndicatorSelector,
    LanguageModal: () => import('../language/languageModal.vue'),
    addLanguageModal: () => import('../language/addLanguageModal.vue'),
    FormBuilder: () => import('../builder/builder.vue'),
    UploadModal: () => import('../UploadModal/index.vue')
  },
  mixins: [
    request(),
    enumerators()
  ],
  props: {
    cancel: {
      type: Function,
      default: () => {}
    },
    onFormUploadComplete: {
      type: Function,
      required: true
    },
    survey: {
      type: Object,
      default: undefined
    },
    languageIndex: {
      type: Number,
      default: undefined
    }
  },
  data() {
    return {
      builderActive: false,
      showDemoDropDown: false,
      selectableIndicators: [],
      selectedIndicators: [],
      loadingIndicators: false,
      removeLanguageConfirmIndex: false,
      isLanguageModalVisible: false,
      isAddLanguageModalVisible: false,
      uploadVisible: false,
      userActionDisabled: false
    };
  },
  computed: {
    ...requestData(['forms']),
    ...mapState({
      metadata: state => state.survey.metadata || {},
      projectId: state => state.survey.project.id,
      surveyId: state => state.survey.surveyId,
      hasOdkbuild: state => state.survey.hasOdkbuild,
      odkbuild: state => state.survey.odkbuild,
      form: state => state.survey.form,
      xml: state => state.survey.xml,
      odkBuildForm: state => state.indicators.odkBuildForm || {},
      loadedSelectedIndicators: state => state.indicators.selectedIndicators,
      possibleIndicators: state => state.indicators.possibleIndicators,
      defaultLanguages: state => state.indicators.defaultLanguages,
      activeLanguages: state => state.indicators.activeLanguages,
      odkError: state => state.indicators.error,
      isSupervisor: state => state.survey.currentUserRoleSystem === 'srvy-sup'
    }),
    hasCoreIndicatorsSelected() {
      return this.selectedIndicators.filter(indicator =>
        indicator.name !== 'general_info_demo' && indicator.category === 'core').length > 0;
    },
    hasBankIndicatorsSelected() {
      return this.selectedIndicators.filter(indicator => indicator.category !== 'core').length > 0;
    }
  },
  watch: {
    possibleIndicators: {
      handler(newValue) {
        if (newValue.length > 0) {
          this.selectableIndicators = newValue;
        } else {
          this.selectableIndicators = [];
        }
      }
    },
    loadedSelectedIndicators: {
      handler(newValue) {
        if (newValue.length > 0) {
          this.selectedIndicators = newValue;
        } else {
          this.selectedIndicators = [];
        }
      }
    }
  },
  created() {
    this.loadAllPossibleIndicators({
      surveyId: this.surveyId
    });
    this.loadSelectedIndicators({
      surveyId: this.surveyId
    });
    this.loadExistingLanguages(this.surveyId);
    if (this.hasOdkbuild) {
      this.getActiveLanguages(this.surveyId);
    }
    this.getForms();
    this.fetchEmumerators(this.projectId, this.surveyId);
  },
  methods: {
    ...mapActions([
      'setFormFormats',
      'setForm',
      'loadSelectedIndicatorBuild',
      'saveSelectedIndicators',
      'loadSelectedIndicators',
      'loadAllPossibleIndicators',
      'resetAllIndicators',
      'setFormBuilderAsChanged',
      'getActiveLanguages',
      'loadExistingLanguages',
      'saveActiveLanguages',
      'getQuestionnaireTemplate'
    ]),
    languages(language) {
      const translatedLanguage = langs.call(this).find((lang) => language === lang.value);
      return translatedLanguage?.label || language;
    },
    openUploadModal() {
      if (this.userActionDisabled) {
        return;
      }
      this.uploadVisible = true;
    },
    selectedIndicatorForCategory(category, subcategory) {
      return this.selectedIndicators.filter(item => item.category === category && item.subcategory === subcategory);
    },
    openLanguageModal() {
      this.isLanguageModalVisible = true;
    },
    openFormBuilder(languages, defaultLanguage) {
      if (this.builderActive) {
        return;
      }
      const messageKey = 'form-builder';
      this.$message.loading({ content: this.$t('components.notifications.loadingForm'), key: messageKey, duration: 60 });
      this.loadSelectedIndicatorBuild({
        surveyId: this.surveyId,
        languages,
        defaultLanguage
      }).then(_ => {
        this.builderActive = true;
      });
    },
    deleteLanguage(key) {
      if (this.userActionDisabled) {
        return;
      }
      this.$message.loading({
        content: this.$t('components.notifications.deletingData')
      });
      this.userActionDisabled = true;
      const languages = this.activeLanguages.filter(item => item.key !== key).map(item => ({ language: item.language, key: item.key, isDefault: item.isDefault }));
      this.saveActiveLanguages({ surveyId: this.surveyId, languages, partial: false }).then(_ => {
        if (this.odkError == null) {
          this.getActiveLanguages(this.surveyId);
          this.removeLanguageConfirmIndex = false;
          this.setFormBuilderAsChanged();
          this.$notification.success({
            message: this.$t('components.notifications.languageDeleted'),
            description: `${this.$t('components.notifications.languageSuccessfullyRemoved')}\n\n${this.$t('components.notifications.formSavedAndSentToSMART')}`
          });
          this.getForms();
        }
      }).finally(_ => {
        this.userActionDisabled = false;
      });
    },
    setDefaultLanguage(key) {
      if (this.userActionDisabled) {
        return;
      }
      this.$message.loading({
        content: this.$t('components.notifications.saving')
      });
      this.userActionDisabled = true;
      const defaultLanguage = { ...this.activeLanguages.find(item => item.key === key) };
      defaultLanguage.isDefault = true;
      this.saveActiveLanguages({ surveyId: this.surveyId, languages: [defaultLanguage], partial: true }).then(_ => {
        if (this.odkError == null) {
          this.getActiveLanguages(this.surveyId);
          if (this.odkError == null) {
            this.setFormBuilderAsChanged();
            this.$notification.success({
              message: this.$t('components.notifications.defaultLanguageUpdated'),
              description: `${this.$t('components.notifications.theDefaultLanguageOfTheForm')}\n\n${this.$t('components.notifications.theDefaultLanguageOfTheForm')}`
            });
            this.getForms();
          }
        }
      }).finally(_ => {
        this.userActionDisabled = false;
      });
    },
    resumeFormBuilder() {
      if (this.builderActive || this.userActionDisabled) {
        return;
      }
      this.userActionDisabled = true;
      const messageKey = 'form-builder';
      this.$message.loading({ content: this.$t('components.notifications.loadingForm'), key: messageKey, duration: 60 });
      this.loadSelectedIndicatorBuild({
        surveyId: this.surveyId
      }).then(_ => {
        this.builderActive = true;
      }).finally(_ => {
        this.userActionDisabled = false;
      });
    },
    closeFormBuilder() {
      this.builderActive = false;
    },
    async destroy() {
      if (this.userActionDisabled) {
        return;
      }
      this.userActionDisabled = true;
      await this.setFormFormats({
        odkbuild: null,
        xml: null
      });
      await this.clear(false);
      this.userActionDisabled = false;
    },
    async clear(cancel) {
      this.resetAllIndicators(this.surveyId);
      this.deleteForm(cancel);
    },
    async onComplete(payload) {
      const result = await this.upload(payload.odkbuild);
      this.closeFormBuilder();
      return result;
    },
    upload(odkbuildOutput) {
      const headers = { 'Content-Type': 'application/json' };
      return this.request({
        method: 'POST',
        url: apiPaths.formJson(this.projectId, this.surveyId),
        headers,
        data: odkbuildOutput,
        params: { formBuilder: true, publish: true, ignoreWarnings: true },
        fulfillProblem: ({ code }) => code === 400.16,
        problemToAlert: ({ code, details }) => {
          if (code === 400.15)
            return this.$t('components.notifications.xlsFormCouldNotBeConverted', {
              error: details.error
            });
          if (code === 409.3 && details.table === 'forms') {
            const { fields } = details;
            if (fields.length === 2 && fields[0] === 'projectId' &&
              fields[1] === 'xmlFormId') {
              const xmlFormId = details.values[1];
              return this.$t('components.notifications.formAlreadyExistsInThisProject', {
                xmlFormId
              });
            }
          }
          return null;
        }
      })
        .then(({ data }) => {
          if (isProblem(data)) {
            this.$alert().blank();
            this.warnings = data.details.warnings;
          } else {
            this.setFormBuilderAsChanged();

            this.$notification.success({
              message: `${this.$t('values.success')}!`,
              description:
                this.$t('components.description.formSavedSentToSmartCollectSuccessfully')
            });
          }
          this.getForms();
          this.onFormUploadComplete();
        });
    },
    getForms() {
      this.$store.dispatch('get', [{
        key: 'forms',
        url: apiPaths.surveyForms(this.projectId, this.surveyId),
        extended: true
      }]).catch(noop);
    },
    deleteForm(cancel, callback = noop) {
      if (this.forms && this.forms.length && this.forms.length > 0) {
        const formId = this.forms[0]._data.xmlFormId;
        this.request({
          method: 'DELETE',
          url: apiPaths.form(this.projectId, formId)
        }).then(() => {
          this.setFormBuilderAsChanged();
          this.getForms();
          this.$store.dispatch('get', [{
            key: 'projects',
            url: '/projects',
            extended: true
          }]).catch(noop);
        }).finally(() => {
          callback();
          if (cancel) {
            this.cancel();
            this.setFormFormats({
              odkbuild: null,
              xml: null
            });
          }
        });
      } else if (cancel) {
        this.cancel();
        this.setFormFormats({
          odkbuild: null,
          xml: null
        });
      } else {
        callback();
      }
    },
    resetIndicators(core) {
      const indicatorsToReset = this.selectedIndicators
        .filter(indicator => (core ? indicator.category !== 'core' || indicator.name === 'general_info_demo' : indicator.category === 'core'));
      this.saveIndicators(indicatorsToReset);
    },
    saveIndicators(indicatorsToSave) {
      this.saveSelectedIndicators({ indicators: indicatorsToSave.map(item => item.id), surveyId: this.surveyId });
    },
    closeLanguageModal() {
      this.isLanguageModalVisible = false;
    },
    closeAddLanguageModal() {
      this.isAddLanguageModalVisible = false;
      this.getActiveLanguages(this.surveyId);
      this.getForms();
    },
    openAddLanguageModal() {
      if (this.userActionDisabled || this.isSupervisor) {
        return;
      }
      this.isAddLanguageModalVisible = true;
    },
    emitLanguageChange(langIndex) {
      this.$emit('languageChange', langIndex);
    },
    async downloadQuestionnaireTemplate() {
      if (this.userActionDisabled) {
        return;
      }
      this.userActionDisabled = true;
      const { blob, headers } = await this.getQuestionnaireTemplate(this.surveyId);
      if (blob) {
        const re = /filename="(.*)"/;
        const fileName = re.exec(headers['content-disposition']);
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = fileName[1] || 'Questionnaire Template';
        document.body.appendChild(link);
        link.click();
        link.remove();
        URL.revokeObjectURL(link.href);
      }
      this.userActionDisabled = false;
    },
    displayBankIndicator() {
      return this.selectedIndicators.some(item => item.category === 'indicator bank');
    },
    displayHouseholdCore() {
      return this.selectedIndicators.some(item => item.category === 'core' && item.subcategory === 'household');
    },
    displayChildCore() {
      return this.selectedIndicators.some(item => item.category === 'core' && item.subcategory === 'child');
    },
    displayHouseholdBankIndicator() {
      return this.selectedIndicators.some(item => item.category === 'indicator bank' && item.subcategory === 'household');
    },
    displayChildBankIndicator() {
      return this.selectedIndicators.some(item => item.category === 'indicator bank' && item.subcategory === 'child');
    },
    displayWomenBankIndicator() {
      return this.selectedIndicators.some(item => item.category === 'indicator bank' && item.subcategory === 'women');
    }
  }
};
</script>

<style lang="scss">
.form-create {
  .form-create-title {
    display: grid;
    grid-template-columns: 50px auto 10%;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }

  @media (max-width: 768px) {
    .form-create-title {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      justify-items: center;
      align-items: center;

      a {
        grid-row-start: 1;
        align-self: flex-start;
      }

      embed {
        margin-right: 0 !important;
        margin-bottom: 5px;
      }
    }
  }

  .ant-tabs-bar {
    margin-bottom: 0;
  }

  .ant-tabs-tab {
    font-size: 18px;
    color: #8e908f;
  }

  .ant-tabs-tab-active {
    color: #363534 !important;
  }

  .ant-tabs-content {
    border: 1px solid #e8e8e8;
    border-top: none;
  }

  .ant-tabs-bar {
    display: flex;
  }

  .ant-tabs-extra-content {
    float: none;
    order: 1;
    display: flex;
    align-items: center;
  }

  .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
    padding: 5px 25px;
    height: auto !important;
    margin-right: 5px;
    font-family: 'DINNextLTPro-Medium';
  }

  .indicator-items {
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    pointer-events: none;
    opacity: 0.4;
    padding: 10px;
  }

  .indicator-items > .divider-top {
    border-top: 1px solid rgba($color: #000000, $alpha: 0.2);
  }
}
.add-icon {
  background:2rem;
  height: 25px;
  width: 25px;
  background-color: #e98300;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.625rem 0 0;
  color: white !important;
  font-weight: bold !important;
  font-size: 1.5em;
}
.add-new-line-text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}
.new-line-row {
  margin-top: 24px;
  display: flex;
  cursor: pointer;
}
.new-line-row-disabled {
  color: #8e908f;
  opacity: 0.5;
  cursor: not-allowed;
}
.delete-language-popup {
  width: 280px;
  padding-left: 10px;
}
.ant-notification-notice-description {
  white-space: pre-wrap;
}
.additional-card .ant-card-body {
  padding: 0 !important
}
.divider-bottom-thick {
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
}
.additional-translation-title {
  line-height: 1.35;
  letter-spacing: normal;
  margin-bottom: 10px;
}
</style>
