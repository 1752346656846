<template>
  <div>
    <a-button v-if="editDisplay" type="link" :data-cy="`add-remove-indicators-button-${indicatorType}-${subcategory}`"
    class="flex align-center pl-0" @click="onEdit">
      <a-tooltip :title="$t('components.titles.addOrEditQuestions')">
        <embed class="mr-4" width="20px" height="20px" src="/pencil-circle.svg">
      </a-tooltip>
      <a class="txt-orange ml-4 txt-semi-bold txt-font-din-medium letter-spacing">
        {{ $t('components.description.addOrRemove') }}
      </a>
    </a-button>
    <div class="mt-20">
      <div v-for="indicator in selected"
        :key="indicator.id"
        class="flex align-center txt-18 saved-indicators">
        <a-icon type="check-circle" theme="filled" style="color: #66c300; font-size: 20px;"/>
        <div class="ml-8 mr-16 selected-indicator-name">
          {{ displayIndicator(indicator) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { translateIndicator } from '@/util/indicators';

export default {
  name: 'QuestionnaireIndicatorSelectDisplay',
  props: {
    selected: {
      type: Array,
      default: () => ([])
    },
    onEdit: {
      type: Function,
      default: () => {}
    },
    editDisplay: {
      type: Boolean,
      default: true
    },
    subcategory: {
      type: String,
      default: undefined
    },
    indicatorType: {
      type: String,
      default: undefined
    }
  },
  methods: {
    displayIndicator(item) {
      const displayName = this.translateIndicator(item.name);
      return `${displayName}${item.abbreviation ? ` (${this.translateAbbrevation(item.abbreviation)})` : ''}`;
    },
    translateAbbrevation(abb) {
      const abbrevation = {
        rCSI: this.$t('components.description.rCSI'),
        HDDS: this.$t('components.description.HDDS'),
        FCS: this.$t('components.description.FCS'),
        HHS: this.$t('components.description.HHS'),
        MUAC: this.$t('components.description.MUAC')
      };
      return abbrevation[abb];
    },
    translateIndicator(indicator) {
      return translateIndicator.call(this, indicator);
    }
  }
};
</script>

<style lang="scss">
</style>
